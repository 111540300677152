<template>
  <div
    :id="containerId"
    data-testid="contact-form-container"
    class="contact-form-container max-w-contact-form-container relative mx-auto flex min-h-[550px] w-full justify-center pb-10 lg:w-auto"
    :class="
      showContainer && 'bg-s-50 shadow-dp-01 rounded-xl px-4 py-4 lg:px-8'
    "
  >
    <!-- Message -->
    <div
      class="left-0 top-0 flex h-full items-center justify-center transition-opacity duration-500"
      :class="{
        'w-0 overflow-hidden lg:invisible lg:absolute lg:w-auto lg:overflow-auto':
          !success,
        'opacity-0': !success,
        'w-full opacity-100': success,
      }"
    >
      <div class="mx-10 my-10 text-center">
        <SuccessMessage :message="message" />

        <div class="flex flex-col items-center justify-center gap-y-2">
          <slot>
            <CommonButton
              v-if="showReturnToHomeButton"
              variant="primary"
              class="text-lg"
              link="/"
            >
              Return to Homepage
            </CommonButton>
            <CommonButton
              v-if="showReturnToFormButton"
              variant="tertiary"
              class="text-lg"
              @click="success = false"
            >
              Return to form
            </CommonButton>
          </slot>
        </div>
      </div>
    </div>

    <!-- Form -->
    <div
      v-if="!success"
      class="flex h-full w-full flex-col items-center justify-center transition-opacity duration-500"
    >
      <h2
        v-if="showDefaultTitle"
        id="signin-modal-title"
        class="mb-12 text-center text-3xl font-extrabold text-gray-900"
      >
        Contact us
      </h2>

      <slot name="description" />
      <FormBuilder
        reverse-button-row
        button-send-class="mx-auto text-sm !w-full"
        :show-link-privacy-policy="showLinkPrivacyPolicy"
        :form-id="contactFormId"
        form-type="contact us"
        :show-success-message="false"
        :show-services="showServices"
        :initial-focus="initialFocus"
        :is-modal="isModal"
        :show-labels="showLabels"
        :default-values="initialValues"
        :always-visible-section-ids="alwaysVisibleSectionIds"
        :ignore-conditionals="ignoreConditionals"
        text-button="Submit"
        @set-success="setSuccess"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModalStore } from '~/stores/modal'
/** components */
import FormBuilder from '~/components/common/FormBuilder.vue'
const SuccessMessage = defineAsyncComponent(
  () => import('~/components/contact/SuccessMessage.vue'),
)
const CommonButton = defineAsyncComponent(
  () => import('~/components/common/Button.vue'),
)

/** types */
interface Props {
  formId?: string | number | null
  formType?: string
  message: string
  containerId?: string
  defaultValues: object
  showLabels: boolean
  showContainer: boolean
  showServices: boolean
  showDefaultTitle: boolean
  showReturnToFormButton: boolean
  showReturnToHomeButton: boolean
  showLinkPrivacyPolicy: boolean
  initialFocus: boolean
  initialValues?: object | null
  isModal: boolean
  alwaysVisibleSectionIds: Array<string | number>
  ignoreConditionals: boolean
}

/** props */
const props = withDefaults(defineProps<Props>(), {
  formId: null,
  formType: 'contact us',
  message:
    'Thank you for contacting us, one of our team will be in touch shortly.',
  containerId: '',
  defaultValues: () => ({}),
  showLabels: true,
  showContainer: true,
  showServices: true,
  showDefaultTitle: false,
  showReturnToFormButton: true,
  showReturnToHomeButton: true,
  showLinkPrivacyPolicy: true,
  initialFocus: true,
  initialValues: null,
  isModal: false,
  alwaysVisibleSectionIds: () => [],
  ignoreConditionals: false,
})

/** app */
const emit = defineEmits(['set-success'])
const { $config } = useNuxtApp()
const { getModal } = useModalStore()
const modal = getModal('contact')

/** refs */
const success = ref<boolean>(false)

/** computed */
const contactFormId = computed(
  () => props.formId ?? $config.public.fmContactFormId,
)
const contactModalOpen = computed(() => modal)

/** watch */
watch(contactModalOpen, (opened) => {
  /**
   * Waits until the modal's fade out animation
   * ends to change the modal's state;
   * otherwise, the form briefly shows up during the animation.
   */
  if (!opened) {
    setTimeout(() => {
      success.value = false
    }, 2000)
  }
})

watch(success, (value) => {
  /**
   * Scrolls up to the success message, either on
   * mobile and tablet resolutions.
   */
  if (value && props.containerId) {
    const viewportWidth = Math.max(
      document?.documentElement.clientWidth || 0,
      window?.innerWidth || 0,
    )

    if (viewportWidth < 1024) {
      document.getElementById(props.containerId).scrollIntoView()
    }
  }
})

/** methods */
const setSuccess = (value) => {
  success.value = value
  emit('set-success', value)
}
</script>
